import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Spin, Button, Form, notification, Input, Col, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// amplify
import { Auth } from 'aws-amplify';

/** Presentational */
import FullWidthWrapper from '../../Components/Styled/FullWidthWrapper';
import EmailConfirmFormWrapper from '../../Components/Styled/EmailConfirmFormWrapper';
import CommonService from '../../Services/CommonService';
import { AUTH_USER_TOKEN_KEY } from '../../Utils/constants';

type State = {
  username: string;
  loading: boolean;
  redirect: boolean;
  confirmationCode: string;
  error: string;
  emailLink: boolean;
};

class ConfirmEmailContainer extends React.Component<RouteComponentProps, State> {
  state = {
    username: '',
    loading: false,
    redirect: false,
    confirmationCode: '',
    error: '',
    emailLink: false
  };

  componentDidMount() {
    if (this.props.location.search) {
      // get username from url params
      let username = this.props.location.search.split('=')[1];
      if (this.props.location.search.indexOf("&code")) {
        let qry = this.props.location.search.replace("?email=","");
        let confirmationCode = qry.split('&code=')[1];
        username = qry.split('&code=')[0];
        let emailLink = true;
        this.setState({ username, confirmationCode, emailLink });
        this.confirmUser(username, confirmationCode);
      } else {
        this.setState({ username });
      }
    }
  }

  confirmUser = (user?:any, code?:any) => {
    this.setState({
      loading: true
    });
    CommonService.postApiExecutor('rest/confirmuserreg', {
      emailId: user ? user : this.state.username, emailVerificationCode: code ? code : this.state.confirmationCode,
    }).then(response => {
      this.setState({
        loading: false
      });
      if (response.data.errorCode === 0) {
        this.handleOpenNotification('success', 'Succesfully confirmed!', 'You will be redirected to login in a few!');
        if(response.data.customData.website_user_only){
          let dnsEpArr = response.data.customData.dns_endpoint.split('://');
          let absEndp = response.data.customData.dns_endpoint;
          if(dnsEpArr.length>1){
            absEndp = dnsEpArr[1];
          }
          window.location.assign(window.location.protocol+'//'+window.location.host+'/login?site=portal&host='+absEndp+'&returnUrl=/');
        } else {
          window.location.assign(process.env.REACT_APP_KPM_URL as any);
        }
        
        //this.props.history.push('/login');
      } else {
        this.handleOpenNotification('error', 'Invalid code', response.data.description);
      }
    })
  }

  handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const { confirmationCode } = this.state;

    // show progress spinner
    this.setState({ loading: true });

    Auth.confirmSignUp(this.state.username, confirmationCode)
      .then(() => {
        this.handleOpenNotification('success', 'Succesfully confirmed!', 'You will be redirected to login in a few!');
      })
      .catch(err => {
        this.handleOpenNotification('error', 'Invalid code', err.message);
        this.setState({
          loading: false
        });
      });
  };

  /**
   * @param  {string} - type
   * @param  {string} - title
   * @param  {string} - message
   *
   * @returns {void} - no value returned
   */
  handleOpenNotification = (type: string, title: string, message: string): void => {
    switch (type) {
      case 'success':
        notification['success']({
          message: title,
          description: message,
          placement: 'topRight',
          duration: 1.5,
          onClose: () => {
            this.setState({ redirect: true });
          }
        });
        break;

      case 'error':
        notification['error']({
          message: title,
          description: message,
          placement: 'topRight',
          duration: 1.5
        });
        break;
    }
  };

  handleOnPaste = (event: React.ClipboardEvent) => {
    event.preventDefault();

    let code = event.clipboardData.getData('Text').trim();

    /** Update input */
    this.setState({ confirmationCode: code });

    // regex to check if string is numbers only
    const reg = new RegExp('^[0-9]+$');

    if (reg.test(code) && code.length === 6) {
      // code is a valid number

      this.setState({ loading: true });

      Auth.confirmSignUp(this.state.username, code)
        .then(() => {
          this.handleOpenNotification('success', 'Succesfully confirmed!', 'You will be redirected to login in a few!');
        })
        .catch(err => {
          this.handleOpenNotification('error', 'Invalid code', err.message);
          this.setState({
            loading: false
          });
        });
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmationCode: event.currentTarget.value });
  };

  render() {
    const { loading, error, confirmationCode, redirect, emailLink } = this.state;

    return (
      <div className="login-main-wrapper ">
      <div className="loginGradientBackground">
      <header className="login-header">
      <div className="row">
        <div className="col-sm-6">
          <img src={require('../../assets/images/konnexsion-logo.png')} alt="" />
        </div>
        <div className="col-sm-6 text-right">
          {/* <p className="text-right donthaveaccount">Don't have an account? <a className="singupaction" href="javascript:void(0)">Sign up</a></p> */}
        </div>
      </div> 
    </header>
    <div className="loginMainContain">
        <div className="container">
                <div className="row">
                  <div className="col-sm-5">
                  <div className="loginContainWidth">
                  <div className="row" >
        { !emailLink && (
          <FullWidthWrapper align="center">
            <EmailConfirmFormWrapper onSubmit={this.handleSubmit}>
              <Row>
                <Col md={24} lg={18}>
                  <div className="full-width">
                    <h2>Check your email</h2>
                    <p>We've sent a six digit confirmation code</p>
                  </div>
                  <Form.Item validateStatus={error && 'error'} help={error} label="Confirmation Code">
                    <Input
                      size="large"
                      type="number"
                      placeholder="Enter confirmation code"
                      onChange={this.handleChange}
                      onPaste={this.handleOnPaste}
                      value={confirmationCode}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} lg={12}>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large">
                    {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} /> : 'Confirm Email'}
                  </Button>
                </Col>
              </Row>
            </EmailConfirmFormWrapper>
            {redirect && <Redirect to={{ pathname: '/login' }} />}
          </FullWidthWrapper>
        )}
        {emailLink && (
          <div className="accountSetupProgress">
            <p>We are setting up your account. Please wait we will redirect you in a moment...</p>
            <p><i className="fa fa-circle-o-notch fa-spin" ></i></p>

           {/*  <button type="submit" className="btn btn-primary btn-block" onClick={()=>this.confirmUser()}>
              {loading ? <Spin indicator={<LoadingOutlined type="loading" style={{ fontSize: 24 }} spin />} /> : 'Confirm Account'}
            </button> */}
          </div>
        )}
      </div>

                    </div>
                    </div>

                    <div className="col-sm-7 login-right-text"><h2>Manage everything on one platform</h2><h4>For letting agents, property management companies, and landlords</h4>
                    {/* <p><a className="EnquireToday" href="https://www.kpm.one">Enquire Today</a></p> */}
                    </div>

                    
                    </div>
                    </div>
                    </div>


    </div>
    
    <div  className="login-footer-msg text-center ">

	Copyright © {new Date().getFullYear()} Konnexsion Property Ltd. All rights reserved.
  {/* <a href="https://legal.kpm.one/eula" target="_blank">Licence Agreement</a> */}
</div>
    </div>




      
    );
  }
}

export default ConfirmEmailContainer;
