import React, { useRef, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { notification, Spin, Row, Col, Input } from 'antd/es';
import { LoadingOutlined, LockOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';

/** App theme */
import { colors } from '../../Themes/Colors';
import FormWrapper from '../../Components/Styled/FormWrapper';
import CommonService from '../../Services/CommonService';
import ReCAPTCHA from "react-google-recaptcha";

type Props = RouteComponentProps;

type FormData = {
  code: string;
  password: string;
};

const reCapKey = process.env.REACT_APP_RECAPTCHA_KEY;

const PasswordResetContainer: React.FC<Props> = ({ location }) => {
  const { register, handleSubmit, setError, formState: { errors }, reset, watch } = useForm<FormData>();
  const recaptchaRef = React.useRef();
  const [loading, setLoading]  = useState(false);
  const [redirect, setRedirect]  = useState(false);

  const validateToNextPassword = (value: string) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+[{\]};:<>|./?]).{8,}$/;

    if (!value) {
      return 'Please enter a password';
    } else if (value.length < 8) {
      return 'Password should be at least 8 characters long';
    } else if (!regex.test(value)) {
      return 'Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character';
    } else {
      return true;
    }
  };

  const onSubmit = async (data: FormData) => {
    try {
      // Show loader
      setLoading(true);
      const token = await (recaptchaRef as any)!.current.executeAsync();

      const { password, code } = data;
      const username = location.search.split('=')[1].trim();

      await Auth.forgotPasswordSubmit(username, code, password);

      // Call API endpoints here
      CommonService.postApiExecutor("rest/updatecognitouser",
        { emailId: username });
    CommonService.postApiExecutor("rest/updateuserattribute",
        { emailId: username, rcToken:token });

      notification.success({
        message: 'Success!',
        description: 'Password reset successful, Redirecting you in a few!',
        placement: 'topRight',
        duration: 1.5,
        onClose: () => {
          setRedirect(true);
        }
      });
    } catch (err:any) {
      notification.error({
        message: 'Error resetting password',
        description: err.message,
        placement: 'topRight',
        duration: 1.5
      });
    }
  };

  return (
    <div className="login-main-wrapper">
      <ReCAPTCHA
        ref={recaptchaRef as any}
        size="invisible"
        sitekey={reCapKey as any}
      />
      <div className="loginGradientBackground">
          <header className="login-header">
            <div className="row">
              <div className="col-sm-6">
                <img src={require('../../assets/images/konnexsion-logo.png')} alt="" />
              </div>
              <div className="col-sm-6 text-right">
                <p className="text-right donthaveaccount">Don't have an account? <a className="singupaction" href="javascript:void(0)">Sign up</a></p>
              </div>
            </div>
          </header>

        <div className="loginMainContain">
          <div className="container">
            <div className="row">
              <div className="col-sm-5">
                <div className="loginContainWidth">
                  
                    <div className="row">
                      <div className="col-sm-12 sign-in-left">
                        <div className="text-left confirmation-text">
                          <p>Check your email for the confirmation code</p>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <form name="password_reset_form" onSubmit={handleSubmit(onSubmit)}>
                          <div className="confirmation-text confirm-code-text form-group">
                            <input
                              type="text"
                              className="customInput form-control"
                              placeholder="Enter your verification code"
                              name="code"
                              autoComplete='new-password'
                              ref={register({ required: 'Please input your confirmation code!' })}
                            />
                            {errors.code && <span>{errors.code.message}</span>}
                          </div>

                          <div className="text-left form-group">
                            <input
                              type="password"
                              className="customInput form-control"
                              placeholder="New Password"
                              name="password"
                              autoComplete='new-password'
                              onChange={(e) => validateToNextPassword(e.target.value)}
                              ref={register({ required: 'Password is required' })}
                            />
                            {errors.password && <span>{errors.password.message}</span>}
                          </div>


                          <div className="form-group text-center">
                            <button type="submit" className="btn btn-primary btn-block btn-login submitBtn">
                              Submit
                            </button>
                          </div>
                        </form>
                        {redirect && <Redirect to={{ pathname: '/login' }} />}
                      </div>
                    </div>
                  
                </div>
              </div>
              <div className="col-sm-7 login-right-text"><h2>Manage everything on one platform</h2><h4>For letting agents, property management companies, and landlords</h4>
              {/* <p><a className="EnquireToday" href="https://www.kpm.one">Enquire Today</a></p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetContainer;
