import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Input, Button, notification, Row, Col, Spin } from 'antd/es';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import CommonService from '../../Services/CommonService';

import ReCAPTCHA from "react-google-recaptcha";

const ForgotPasswordForm = () => {
  const { handleSubmit, register, errors, formState } = useForm();
  const [redirect, setRedirect]  = useState(false);
  const [username, setUsername]  = useState('');
  const recaptchaRef = React.useRef();
  const history = useHistory();

  const reCapKey = process.env.REACT_APP_RECAPTCHA_KEY || '6Lfj6v8ZAAAAAGoIFhzh5fm63FUrJLwsko4XWiDU';

  const onSubmit = async (data:any) => {
    // Handle form submission here
    const token = await (recaptchaRef as any)!.current.executeAsync();
    console.log(data);
    setUsername(data.username);
    CommonService.postApiExecutor("rest/forgotuserpwd",
      { emailId: data.username, rcToken:token }).then((response:any) => {
        if (response.data.errorCode === 0) {
          if (response.data.customData == 'RESEND') {
            notification.success({
              message: 'Redirecting you in a few!',
              description: 'Temporary password has been sent to your email!',
              placement: 'topRight',
              duration: 1.5,
              onClose: () => {
                history.push("/login");
              }
            });
          } else {
            notification.success({
              message: 'Redirecting you in a few!',
              description: 'Account confirmed successfully!',
              placement: 'topRight',
              duration: 1.5,
              onClose: () => {
                setRedirect(true);
              }
            });
          }
        } else {
          notification.error({
            message: 'Password Reset Failed',
            description: response.data.description,
            placement: 'topRight',
            duration: 1.5
          });
        }
        /* Auth.forgotPassword(username, response.data.customData === 'Y'? {"create": "Y"}: {"create": "N"})
        .then(data => {
          notification.success({
            message: 'Redirecting you in a few!',
            description: 'Account confirmed successfully!',
            placement: 'topRight',
            duration: 1.5,
            onClose: () => {
              this.setState({ redirect: true });
            }
          });
        })
        .catch(err => {
          notification.error({
            message: 'User confirmation failed',
            description: err.message,
            placement: 'topRight',
            duration: 1.5
          });
          this.setState({ loading: false });
        }); */
      })
  };

  return (
    <div className="login-main-wrapper">
      <ReCAPTCHA
        ref={recaptchaRef as any}
        size="invisible"
        sitekey={reCapKey as any}
      />
      <div className="loginGradientBackground">
        <header className="login-header">
            <div className="row">
              <div className="col-sm-6">
                <img src={require('../../assets/images/konnexsion-logo.png')} alt="" />
              </div>
              <div className="col-sm-6 text-right">
                <p className="text-right donthaveaccount">Don't have an account? <a className="singupaction" href="javascript:void(0)">Sign up</a></p>
              </div>
            </div>
          </header>
        <div className="loginMainContain">
          <div className="container">
            <div className="row">
              <div className="col-sm-5">
                <div className="loginContainWidth">
                  <div className="row">
                    <div className="col-sm-12 sign-in-left">
                      <h3 className="singInyourAccount text-left">Sign in to your account</h3>
                      <p className="text-left forgetinformationtext">
                        Enter the email address associated with your account and we will send you a link to reset your password.
                      </p>
                    </div>
                    <div className="col-sm-12">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <p className="forgotemailAddress">Email Address</p>
                        <div className="login-form">
                          <div className="form-group">
                            <input
                              className="form-control"
                              name="username"
                              ref={register({ required: 'Please enter your username' })}
                              placeholder="Username"
                            />
                            {errors.username && <span>{errors.username.message}</span>}
                          </div>
                          <div className="form-group text-center">
                            <Row>
                              <Col lg={24}>
                                <Button
                                  style={{ width: '100%' }}
                                  type="primary"
                                  htmlType="submit"
                                  className="btn btn-primary btn-block btn-login"
                                  disabled={formState.isSubmitting}
                                >
                                  {formState.isSubmitting ? (
                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
                                  ) : (
                                    'Request Password Reset'
                                  )}
                                </Button>
                              </Col>
                              
                              <Col lg={24}>
                                <Link to="/login">Ooh! Wait I've remembered</Link>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </form>

                      {redirect && (
                            <Redirect
                            to={{
                                pathname: '/reset-password',
                                search: `?username=${username}`
                            }}
                            />
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-7 login-right-text"><h2>Manage everything on one platform</h2><h4>For letting agents, property management companies, and landlords</h4>
              {/* <p><a className="EnquireToday" href="https://www.kpm.one">Enquire Today</a></p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-footer-msg text-center">
      Copyright © 2021 Konnexsion Property Ltd. All rights reserved.
      {/* <a href="https://legal.kpm.one/eula" target="_blank">Licence Agreement</a> */}
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
