import React, { useState } from 'react';
import { Link} from 'react-router-dom';
import { Auth, } from 'aws-amplify';
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { notification } from 'antd';
import pageConf from '../../pageConfig.json';
import SubscriptionForm from '../ProfileContainer/Pages/SubscriptionForm';
import { Button } from 'react-bootstrap';

import CommonService from '../../Services/CommonService';
import { AUTH_USER_TOKEN_KEY } from '../../Utils/constants';

type User = {
  fname: string;
  lname: string;
  password: string;
  confirmPassWord: string;
  email: string;
  phoneNumber: number;
};

const SignUpContainer = (props:any) => {
  const query = props.location.search;
  const { register, handleSubmit, errors, getValues } = useForm<User>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedEType, setSelectedEtype] = useState("85");
  const [signupComplete,setSignupComplete] = useState(false);
  const [signupData, setSignupData] = useState({userAttributes:[{name:'',value:''}]});



  const currentYear = new Date().getFullYear();
  const licenceAgreementUrl = process.env.REACT_APP_LICENCE_URL || "https://legal.kpm.one/eula";
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showAddSubscription, setShowSubscription] = useState(false);
  const handleShow = () => setShowSubscription(true);
  const handleClose = () => setShowSubscription(false);


  const etypeChange = (event:any) => {
    setSelectedEtype(event.target.value);
  }
  
  const onSubmit = (data: User) => {
    // event.preventDefault();
    let { fname, lname, email, password, phoneNumber } = data;
    setIsSubmitting(true);
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: `${fname} ${lname}`,
        phone_number: phoneNumber
      }
    })
      .then(user => {

        notification.success({
          message: 'Succesfully logged in!',
          description: 'Logged you in successfully! Launching app in a few seconds.',
          placement: 'topRight',
          duration: 1.5
        });
        setIsSubmitting(false);
        /* if (query && query.indexOf("kpm") > -1) {
          window.location.assign("https://app.kpmdev.one/login#id_token=" + user.signInUserSession.accessToken.jwtToken);
        } */
      })
      .catch(err => {
        setIsSubmitting(false);
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight'
        });

        console.log(err);
      });
  };

  const continueLogin = ()=>{

    let params = {
      emailId:signupData && signupData.userAttributes.filter(x => x.name == "email")[0].value
    }
    CommonService.postApiExecutor("rest/getuserstatus", params,localStorage.getItem(AUTH_USER_TOKEN_KEY)
      ).then(response => {
        if (response.data.errorCode === 1) {
          notification.error({
            message: 'Error',
            description: response.data.description,
            placement: 'topRight'
          })
        } else {
          if (response.data.customData == "CONFIRMED") {
            window.location.assign(window.location.protocol+'//'+window.location.host+"/login?site=kpm");
          } else {

            notification.error({
              message: 'Error',
              description: "Please check your email and click on the “Activation Link”",
              placement: 'topRight'
            })
          }
        }
      },
      err => {
  
      })
  }

  const resendMail = ()=> {

    let params = {
      emailId:signupData && signupData.userAttributes.filter(x => x.name == "email")[0].value
    }

    CommonService.postApiExecutor("rest/resendusercode", params,localStorage.getItem(AUTH_USER_TOKEN_KEY)
      ).then(response => {
        if (response.data.errorCode === 1) {
          notification.error({
            message: 'Error',
            description: response.data.description,
            placement: 'topRight'
          })
        } else {
          notification.success({
            message: 'Succes',
            description: "Please check your email for activation link",
            placement: 'topRight',
            duration: 1.5
          });
        }
      },
      err => {
  
    });
	}
  return (

    <div className="login-main-wrapper">
      <div className="loginGradientBackground">
        <header className="login-header">
          <div className="row">
            <div className="col-sm-6">
              <img src={require('../../assets/images/konnexsion-logo.png')} alt="" />
            </div>
            <div className="col-sm-6 text-right">
              <p className="text-right donthaveaccount">Already have an account?
                  <Link to="/login" className="singupaction"> Sign In</Link>
              </p>
            </div>
          </div>
        </header>

        <div className="loginMainContain">
            <React.Fragment>
                <div className="container">
                    <div className="row">
                          <div className="col-sm-7">
                              
                                  {!signupComplete && (
                                  <div className="loginContainWidth" style={{width:640}}>
                                  <SubscriptionForm 
                                    entityDetails='' 
                                    agreedToTerms={agreedToTerms} 
                                    setAgreedToTerms={setAgreedToTerms}
                                    closeSubscriptionModal={handleClose}
                                    fetchSubscriptionList=''
                                    isLoginForm='Y'
                                    location={props.location}
                                    setSignupComplete={setSignupComplete}
                                    setSignupData={setSignupData}
                                    />
                                    </div>
                                    )
                                  }
                                  {signupComplete && (
                                    <div className="loginContainWidth" style={{width:420}}>
                                        <div className="login-box-body">
                                        <div className="row rowTopBottompadding">
                                          <div className="col-sm-12 text-center">
                                            <h3 className="singInyourAccount" style={{color: '#43af85'}}>Thank you for completing registration.</h3>
                                          </div>
                                        </div>
                                        <div className="row " style={{color: '#43af85'}}>
                                          <div className="col-sm-12 text-center">
                                            <p>In order for us to setup your account you need to verify your email address. Please check your email and click on the activation link.</p>
                                          </div>
                                        </div>
                                        {/* <div className="row rowTopBottompadding">
                                          <div className="col-sm-12 text-center">
                                            
                                            <Button variant="primary" onClick={()=>{continueLogin()}}>Continue</Button>
                                          </div>
                                        </div> */}
                                        <div className="row rowTopBottompadding">
                                          <div className="col-sm-12 text-center">
                                            
                                            <Button variant="secondary" onClick={()=>{resendMail()}}>Resend Activation</Button>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  )}
                              
                          </div>
                          <div className="col-sm-5 login-right-text">
                            <h2>{pageConf.title_1}</h2>
                            <h4>{pageConf.title_2}</h4>
                            {/* <p>
                              <a className="EnquireToday" href={pageConf.enquire_url}>
                                {pageConf.enquire_text}
                              </a>
                            </p> */}
                          </div>
                    </div>
                </div>
            </React.Fragment>
        </div>
      </div>
      <div className="login-footer-msg text-center">
        Copyright © {currentYear} Konnexsion Property Ltd. All rights reserved.
        {/* <a href={licenceAgreementUrl} target="_blank">Licence Agreement</a> */}
      </div>
    </div>
  );
}

export default SignUpContainer;
